<template>
    <div class="flex">
        <input
            :value="value"
            type="checkbox"
            v-bind="$attrs"
            class="hidden"
            @input="$emit('input')"
        />
        <div
            class="relative mr-2 mt-1 h-4 w-4 flex-shrink-0 cursor-pointer rounded-sm border transition-all duration-500"
            :class="{
                'border-primary bg-primary': value,
                'border-neutral-lighter bg-white': !value,
            }"
            @click="$emit('input', !value)"
        >
            <Icon
                :icon="['far', 'check']"
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-xs text-transparent"
                :class="{
                    'text-white': value,
                }"
            />
        </div>
        <slot name="default" />
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    inheritAttrs: false,
})
export default class InputCheckbox extends Vue {
    @Prop({ type: Boolean, default: false }) value;
}
</script>
