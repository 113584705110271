<template>
    <div class="flex h-full w-full overflow-hidden">
        <div class="navbar-side">
            <Logo class="mx-auto mb-20 w-3/5" :dark-mode="true" />
            <template v-if="isThemeClient">
                <div class="py-4">
                    <router-link
                        class="inline-block text-white outline-none transition-all duration-500 hover:text-primary-darker focus:text-primary-darker"
                        active-class="text-primary focus:text-primary-darker"
                        :to="{ name: 'ClientCalendar' }"
                    >
                        <Icon :icon="['far', 'calendar']" class="align-center text-xl" />
                        <span class="ml-6">{{ $t('navigation.calendar') }}</span>
                    </router-link>
                </div>
                <div class="py-4">
                    <router-link
                        class="inline-block text-white outline-none transition-all duration-500 hover:text-primary-darker focus:text-primary-darker"
                        active-class="text-primary focus:text-primary-darker"
                        :to="{ name: 'ClientProfessionals' }"
                    >
                        <Icon :icon="['far', 'address-book']" class="align-center text-xl" />
                        <span class="ml-6">{{ $t('navigation.professionals') }}</span>
                    </router-link>
                </div>
            </template>
            <template v-else>
                <div class="relative">
                    <button
                        class="btn w-full py-3 capitalize"
                        :class="{ highlighted: onboardingSlide === 'create-event-slide' }"
                        @click="showEventActions = !showEventActions"
                    >
                        <Icon :icon="['far', 'circle-plus']" />
                        <span class="ml-3">{{ $t('navigation.create_event') }}</span>
                    </button>
                    <transition enter-class="opacity-0" leave-to-class="opacity-0">
                        <NewEventPopup
                            v-if="showEventActions"
                            v-on-clickaway="hideEventActions"
                            @open-modal="openModal"
                        />
                    </transition>
                </div>
                <div class="pb-4 pt-12">
                    <router-link
                        class="inline-block text-white outline-none transition-all duration-500 hover:text-primary-darker focus:text-primary-darker"
                        active-class="text-primary focus:text-primary-darker"
                        :to="{ name: 'ProfessionalCalendar' }"
                    >
                        <div class="inline-block w-12">
                            <Icon :icon="['far', 'calendar']" class="align-center text-xl" />
                        </div>
                        <span>{{ $t('navigation.calendar') }}</span>
                    </router-link>
                </div>
                <div class="py-4">
                    <router-link
                        :class="{ highlighted: onboardingSlide === 'vouchers-slide' }"
                        class="inline-block text-white outline-none transition-all duration-500 hover:text-primary-darker focus:text-primary-darker"
                        active-class="text-primary focus:text-primary-darker"
                        :to="{ name: 'ProfessionalVouchers' }"
                    >
                        <div class="inline-block w-12">
                            <Icon
                                :icon="['far', 'ticket']"
                                class="align-center -rotate-45 transform text-xl"
                            />
                        </div>
                        <span>{{ $t('navigation.vouchers') }}</span>
                    </router-link>
                </div>
                <div class="py-4">
                    <router-link
                        :class="{ highlighted: onboardingSlide === 'invite-clients-slide' }"
                        class="inline-block text-white outline-none transition-all duration-500 hover:text-primary-darker focus:text-primary-darker"
                        active-class="text-primary focus:text-primary-darker"
                        :to="{ name: 'ProfessionalClients' }"
                    >
                        <div class="inline-block w-12">
                            <Icon :icon="['far', 'address-book']" class="align-center text-xl" />
                        </div>
                        <span>{{ $t('navigation.client_book') }}</span>
                    </router-link>
                </div>
            </template>
            <div
                class="absolute bottom-0 left-0 flex w-64 cursor-pointer items-center p-6"
                @click="showProfileActions = !showProfileActions"
            >
                <Avatar :profile="profile" size="small" />
                <div class="pl-3 text-white" style="width: calc(100% - 3rem)">
                    <div class="flex items-center gap-1">
                        <div
                            v-if="profile && profile.full_name"
                            class="w-full truncate text-lg"
                            :title="profile.full_name"
                        >
                            {{ profile.full_name }}
                        </div>
                        <free-label />
                    </div>
                    <div class="w-full truncate text-sm font-light italic">
                        <span v-if="isThemeClient">{{ $t('navigation.client') }}</span>
                        <span v-else>{{ $t('navigation.professional') }}</span>
                    </div>
                </div>
                <transition enter-class="opacity-0" leave-to-class="opacity-0">
                    <div
                        v-if="showProfileActions"
                        v-on-clickaway="hideProfileActions"
                        class="absolute top-0 -translate-y-full transform rounded bg-white p-4 transition-all duration-500"
                        style="width: calc(100% - 3rem)"
                    >
                        <router-link
                            :to="{
                                name: isThemeClient ? 'ClientProfile' : 'ProfessionalProfile',
                            }"
                            class="mb-4 block hover:text-primary-darker focus:text-primary-darker"
                        >
                            <Icon :icon="['far', 'user']" />
                            <span class="ml-4">{{ $t('navigation.my_profile') }}</span>
                        </router-link>
                        <router-link
                            :to="{
                                name: isThemeClient ? 'ClientSettings' : 'ProfessionalSettings',
                            }"
                            class="mb-4 inline-block hover:text-primary-darker focus:text-primary-darker"
                        >
                            <Icon :icon="['far', 'gear']" />
                            <span class="ml-4">{{ $t('navigation.settings') }}</span>
                        </router-link>
                        <router-link
                            :to="{
                                name: isThemeClient ? 'ProfessionalCalendar' : 'ClientCalendar',
                            }"
                            class="mb-4 flex hover:text-primary-darker focus:text-primary-darker"
                        >
                            <Icon :icon="['far', 'people-arrows']" class="people-icon my-auto" />
                            <p class="ml-4">
                                {{
                                    isThemeClient
                                        ? $t('navigation.switch_to_pro')
                                        : $t('navigation.switch_to_client')
                                }}
                            </p>
                        </router-link>
                        <button
                            class="block text-error hover:text-red-700 focus:outline-none"
                            @click="logout"
                        >
                            <Icon :icon="['far', 'arrow-right-from-bracket']" />
                            <span class="ml-4">{{ $t('common.auth.log_out') }}</span>
                        </button>
                    </div>
                </transition>
            </div>
        </div>
        <div class="main-view">
            <router-view>
                <router-view name="form" />
            </router-view>
        </div>
        <div class="navbar z-40">
            <template v-if="isThemeClient">
                <router-link
                    v-slot="{ isActive, href, navigate }"
                    :to="{ name: 'ClientCalendar' }"
                    custom
                >
                    <a
                        :href="href"
                        :class="['navbar-item', isActive && 'navbar-item--active']"
                        @click="navigate"
                    >
                        <Icon :icon="[isActive ? 'fas' : 'far', 'calendar']" />
                        <div class="text-xs">{{ $t('navigation.calendar') }}</div>
                    </a>
                </router-link>
                <router-link
                    v-slot="{ isActive, href, navigate }"
                    :to="{ name: 'ClientProfessionals' }"
                >
                    <a
                        :href="href"
                        :class="['navbar-item', isActive && 'navbar-item--active']"
                        @click="navigate"
                    >
                        <Icon :icon="[isActive ? 'fas' : 'far', 'address-book']" />
                        <div class="text-xs">{{ $t('navigation.professionals') }}</div>
                    </a>
                </router-link>
                <router-link
                    v-slot="{ isActive, href, navigate }"
                    :to="{ name: 'ClientProfile' }"
                    custom
                >
                    <a
                        :href="href"
                        :class="['navbar-item', isActive && 'navbar-item--active']"
                        @click="navigate"
                    >
                        <Icon :icon="[isActive ? 'fas' : 'far', 'user']" />
                        <div class="text-xs">{{ $t('navigation.my_profile') }}</div>
                    </a>
                </router-link>
            </template>
            <template v-else>
                <router-link
                    v-slot="{ isActive, href, navigate }"
                    :class="{ highlighted: onboardingSlide === 'create-event-slide' }"
                    :to="{ name: 'ProfessionalCalendar' }"
                >
                    <a
                        :href="href"
                        :class="['navbar-item', isActive && !isOnboarding && 'navbar-item--active']"
                        @click="navigate"
                    >
                        <Icon :icon="[isActive ? 'fas' : 'far', 'calendar']" />
                        <div class="text-xs">{{ $t('navigation.calendar') }}</div>
                    </a>
                </router-link>
                <router-link
                    v-slot="{ isActive, href, navigate }"
                    :class="{ highlighted: onboardingSlide === 'vouchers-slide' }"
                    :to="{ name: 'ProfessionalVouchers' }"
                >
                    <a
                        :href="href"
                        :class="['navbar-item', isActive && !isOnboarding && 'navbar-item--active']"
                        @click="navigate"
                    >
                        <Icon
                            :icon="[isActive ? 'fas' : 'far', 'ticket']"
                            class="rotate-45 scale-90 transform"
                        />
                        <div class="text-xs">{{ $t('navigation.vouchers') }}</div>
                    </a>
                </router-link>
                <div class="relative">
                    <button
                        class="btn"
                        data-cy="new-event-mobile"
                        @click="showEventActions = !showEventActions"
                    >
                        <Icon
                            :icon="['far', 'plus']"
                            class="transform transition-all duration-500"
                            :class="{
                                'rotate-45': showEventActions,
                            }"
                        />
                    </button>
                    <transition
                        enter-class="translate-y-1/2 opacity-0"
                        leave-to-class="translate-y-1/2 opacity-0"
                    >
                        <NewEventPopup
                            v-if="showEventActions"
                            v-on-clickaway="hideEventActions"
                            mobile
                            @open-modal="openModal"
                        />
                    </transition>
                </div>
                <router-link
                    v-slot="{ isActive, href, navigate }"
                    :class="{ highlighted: onboardingSlide === 'invite-clients-slide' }"
                    :to="{ name: 'ProfessionalClients' }"
                >
                    <a
                        :href="href"
                        :class="['navbar-item', isActive && !isOnboarding && 'navbar-item--active']"
                        @click="navigate"
                    >
                        <Icon :icon="[isActive ? 'fas' : 'far', 'address-book']" />
                        <div class="text-xs">{{ $t('navigation.client_book') }}</div>
                    </a>
                </router-link>
                <router-link
                    v-slot="{ isActive, href, navigate }"
                    :to="{ name: isThemeClient ? 'ClientProfile' : 'ProfessionalProfile' }"
                >
                    <a
                        :href="href"
                        :class="['navbar-item', isActive && !isOnboarding && 'navbar-item--active']"
                        @click="navigate"
                    >
                        <Icon :icon="[isActive ? 'fas' : 'far', 'user']" />
                        <div class="text-xs">{{ $t('navigation.my_profile') }}</div>
                    </a>
                </router-link>
            </template>
        </div>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import Logo from '@/components/general/Logo';
import Avatar from '@/components/general/Avatar';
import NewEventPopup from '@/components/general/NewEventPopup';
import EventModal from '@/components/modals/EventModal';
import ClassModal from '@/components/modals/ClassModal';
import SlotModal from '@/components/modals/SlotModal';
import { directive as onClickaway } from 'vue-clickaway';
import { AUTH_SIGNOUT, CALENDAR_DRAFT_EVENT_SET } from '@/constants';
import FreeLabel from '@/components/general/FreeLabel';

@Component({
    components: {
        Logo,
        Avatar,
        NewEventPopup,
        FreeLabel,
    },
    directives: {
        onClickaway: onClickaway,
    },
})
export default class Navigation extends Vue {
    showEventActions = false;
    showProfileActions = false;
    draftModalProps = {};

    modals = {
        EventModal,
        ClassModal,
        SlotModal,
    };

    mounted() {
        this.draftModalProps = {};
        this.$root.$on('new-event-popup-open', ({ draftModalProps }) => {
            if (this.isThemeClient) return;
            this.draftModalProps = draftModalProps;
            this.showEventActions = true;
        });
    }

    created() {
        // Close the popup when navigating back
        const unregisterRouterGuard = this.$router.beforeEach((_, __, next) => {
            this.hideEventActions();
            next();
        });

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard();
        });
    }

    get isThemeClient() {
        return this.$store.getters.isThemeClient;
    }

    get isOnboarding() {
        return this.$store.state.onboarding.show;
    }

    get onboardingSlide() {
        return this.$store.state.onboarding.onboardingSlide;
    }

    get profile() {
        return this.$store.state.profile.user?.[this.$store.state.config.theme];
    }

    hideProfileActions() {
        if (this.showProfileActions) {
            this.showProfileActions = false;
        }
    }

    hideEventActions() {
        if (this.showEventActions) {
            this.$store.commit(CALENDAR_DRAFT_EVENT_SET, null);
            this.showEventActions = false;
        }
    }

    openModal(modal) {
        // Reset the draft event from the calendar if any.
        this.$store.commit(CALENDAR_DRAFT_EVENT_SET, null);
        this.$root.$emit('modal-open', {
            modal: this.modals[modal],
            props: this.draftModalProps,
        });
        this.draftModalProps = {};
    }

    async logout() {
        await this.$store.dispatch(AUTH_SIGNOUT);
        this.$router.push({
            name: this.isThemeClient ? 'ClientAuthLogIn' : 'ProfessionalAuthLogIn',
        });
    }
}
</script>

<style lang="postcss">
.highlighted {
    @apply relative z-50 text-primary outline-none;
}

.navbar-side {
    @apply hidden h-full w-64 flex-none bg-layer-dark px-8 pt-8 text-lg font-normal shadow;
}

.main-view {
    @apply h-full w-full overflow-y-auto overflow-x-hidden pb-16;
}

.navbar {
    @apply fixed bottom-0 flex h-16 w-full items-center justify-around bg-white text-xl font-normal shadow-rev;
    padding-bottom: calc(0.4 * env(safe-area-inset-bottom));
}

.navbar-item {
    @apply flex w-10 flex-col items-center justify-center rounded-full pt-2 outline-none transition-all duration-500;
}

.navbar-item:hover {
    @apply text-primary-darker;
}

.navbar-item:focus,
.navbar-item:active {
    @apply text-primary outline-none;
}

.navbar-item--active {
    @apply text-primary;
}

@media (orientation: landscape) {
    .navbar {
        display: none;
    }

    .main-view {
        padding-bottom: 0;
    }
}

@media (min-width: 900px) {
    .navbar {
        display: none;
    }

    .navbar-side {
        display: block;
    }

    .main-view {
        padding-bottom: 0;
    }
}

.svg-inline--fa.people-icon {
    width: 1em;
}
</style>
